import React, { memo, useState } from "react";
import TextInput from "components/Form/TextInput";
import { useForm, Controller } from "react-hook-form";
import SelectComponent from "components/Form/Select";
import Checkbox from "components/Form/Checkbox";
import { ButtonPink } from "components/Button";
import emailjs from "@emailjs/browser";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

const processOptions = [
  { value: "I’m ready to outsource", label: "I’m ready to outsource" },
  { value: "Just Looking", label: "Just Looking" },
  {
    value: "Interested but have some questions",
    label: "Interested but have some questions",
  },
];

const budgetOptions = [
  { value: "20,000k >", label: "20,000k >" },
  { value: "10,000k - 20,000k", label: "10,000k - 20,000k" },
  { value: "5,000k - 10,000k", label: "5,000k - 10,000k" },
  { value: "1,000k - 5,000k", label: "1,000k - 5,000k" },
  { value: "By discuss", label: "By discuss" },
];

const experienceOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const campaignOptions = [
  { value: "I’m ready now!", label: "I’m ready now!" },
  { value: "1-2 weeks", label: "1-2 weeks" },
  { value: "1-2 months", label: "1-2 months" },
  { value: "I’m not ready yet", label: "I’m not ready yet" },
];

const defaultValues = {
  name: "",
  email: "",
  whatsapp_number: "",
  social_media: "",
  process: "",
  budget: "",
  experience: "",
  campaign: "",
  selectedServices: "",
};

const BrandForm = () => {
  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [completeFillForm, setCompleteFillForm] = useState(false);

  // Watch the 'selectedValues' field for changes
  const selectedServices = watch("selectedServices", []);

  // Function to handle checkbox changes
  const handleCheckboxChange = (value) => {
    // Get the current value of the hidden input field
    const currentValue = selectedServices || [];

    // Check if the checkbox value is already in the array
    const isChecked = currentValue.includes(value);

    // Update the hidden input field based on checkbox state
    if (isChecked) {
      setValue(
        "selectedServices",
        currentValue.filter((item) => item !== value)
      );
    } else {
      setValue("selectedServices", [...currentValue, value]);
    }
  };

  const sendEmail = async (data) => {
    setIsLoading(true);
    let formData = data;

    const serviceList = data.selectedServices?.join(", ");

    formData.name = data.name;
    formData.email = data.email;
    formData.whatsapp_number = data.whatsapp_number;
    formData.social_media = data.social_media;
    formData.process = data.process;
    formData.services = serviceList;
    formData.budget = data.budget;
    formData.experience = data.experience;
    formData.campaign = data.campaign;

    await emailjs
      .send(
        "service_i2nu1lv",
        "template_trnogdy",
        formData,
        "xsgLO8Y31LdVFPlzv"
      )
      .then(
        (result) => {
          setIsLoading(false);
          setCompleteFillForm(true);
          console.log(result.text);
        },
        (error) => {
          setIsLoading(false);
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="w-full block relative z-[100] pb-[80px] md:pb-[200px]">
        <div className="max-w-[800px] mx-auto px-16">
          <form onSubmit={handleSubmit(sendEmail)}>
            <div className="">
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Your Name
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "name",
                    type: "text",
                    placeholder: "Your name",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Email
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "email",
                    type: "email",
                    placeholder: "hello@eternalpetite.com",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Whatsapp Number
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "whatsapp_number",
                    type: "text",
                    placeholder: "+628123456789",
                    formatRegex: /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/,
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Your social media
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "social_media",
                    type: "text",
                    placeholder: "@eternalpetite",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  At what stage of the process are you?
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "process",
                    options: processOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  What services are you interested in?
                </label>
                <div
                  className={`w-full bg-primary-300 ${
                    completeFillForm ? "!bg-[#17181A] pointer-events-none" : ""
                  }`}
                >
                  <Controller
                    name="selectedServices"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                  <Checkbox
                    {...{
                      register,
                      name: "influencer_marketing",
                      value: "Influencer Marketing",
                      label: "Influencer Marketing",
                      onChange: () =>
                        handleCheckboxChange("Influencer Marketing"),
                      checked: selectedServices.includes(
                        "Influencer Marketing"
                      ),
                      className: completeFillForm ? "text-neutral-350" : "",
                    }}
                  />
                  <Checkbox
                    {...{
                      register,
                      name: "content_creation",
                      value: "Content Creation",
                      label: "Content Creation",
                      onChange: () => handleCheckboxChange("Content Creation"),
                      checked: selectedServices.includes("Content Creation"),
                      className: completeFillForm ? "text-neutral-350" : "",
                    }}
                  />
                  <Checkbox
                    {...{
                      register,
                      name: "live_host",
                      value: "Live Host",
                      label: "Live Host",
                      onChange: () => handleCheckboxChange("Live Host"),
                      checked: selectedServices.includes("Live Host"),
                      className: completeFillForm ? "text-neutral-350" : "",
                    }}
                  />
                  <Checkbox
                    {...{
                      register,
                      name: "website_creation",
                      value: "Website Creation",
                      label: "Website Creation",
                      onChange: () => handleCheckboxChange("Website Creation"),
                      checked: selectedServices.includes("Website Creation"),
                      className: completeFillForm ? "text-neutral-350" : "",
                    }}
                  />
                </div>
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Budget Estimation for all package
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "budget",
                    options: budgetOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Have you worked with a social media or creative agency before?
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "experience",
                    options: experienceOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Whats your campaign timeline?
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "campaign",
                    options: campaignOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center mt-[40px] md:mt-[80px] md:max-w-[312px] mx-auto">
              <ButtonPink
                type="submit"
                value="send"
                className={`${
                  completeFillForm
                    ? "text-primary-900 border-[#BB8A3B] !bg-primary-500"
                    : ""
                }`}
                disabled={completeFillForm ? true : false}
              >
                {completeFillForm ? "Form has been submitted." : "Sent"}
              </ButtonPink>
              {completeFillForm && (
                <span className="mt-16 text-neutral-100 text-14 leading-[18px]">
                  *Thank you for choosing us to collaborate :) *We will contact
                  you via WhatsApp around 10 work days{" "}
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading..."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 10000,
            overflow: isLoading ? "hidden" : "scroll",
          }),
        }}
      ></LoadingOverlay>
    </>
  );
};

export default memo(BrandForm);
