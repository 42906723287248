import React from "react";
import { MainSliderMobile } from "components/Slider";
import { Link } from "react-router-dom";
import CardSliderDrawerMobile from "components/Cards/CardSliderDrawerMobile";
import "./style.css";
import withDrawer from "utils/withDrawer";

const HomePortfolio = (props) => {
  const handleBeforeChange = () => {
    props.setOpenDrawer((prevState) => {
      const updatedState = {};

      Object.keys(prevState).forEach((key) => {
        updatedState[key] = false;
      });

      return updatedState;
    });
  };

  return (
    <>
      <div className="w-full relative">
        <div className="py-[40px] px-16">
          <h1 className="text-[64px] leading-[51.2px] text-neutral-100 font-taviraj">
            Our Portfolio
          </h1>
        </div>
        <div className="">
          <MainSliderMobile beforeChange={handleBeforeChange}>
            {props.portfolio?.mobile?.map((item, index) => (
              <div className="!block" key={index}>
                <CardSliderDrawerMobile
                  id={index}
                  thumbnail={item.thumbnail}
                  logo={item.logo}
                  openDrawer={props.openDrawer}
                  handleOpenDrawer={props.handleOpenDrawer}
                  contentDrawer={
                    <>
                      <div className="text-14 text-neutral">
                        <div className="mb-8 last:mb-0">
                          <span className="block font-taviraj leading-[23.88px]">
                            Brand :
                          </span>
                          <span className="block font-light leading-[17.5px]">
                            {item.brand}
                          </span>
                        </div>
                        <div className="mb-8 last:mb-0">
                          <span className="block font-taviraj leading-[23.88px]">
                            Service Type :
                          </span>
                          <span className="block font-light leading-[17.5px]">
                            {item.service_type}
                          </span>
                        </div>
                        <div className="mb-8 last:mb-0">
                          <span className="block font-taviraj leading-[23.88px]">
                            Talent :
                          </span>
                          <span className="block font-light leading-[17.5px]">
                            {item.talent}
                          </span>
                        </div>
                        <div className="mb-8 last:mb-0">
                          <span className="block font-taviraj leading-[23.88px]">
                            Platform :
                          </span>
                          <span className="block font-light leading-[17.5px]">
                            {item.platform}
                          </span>
                        </div>
                        <div className="mb-8 last:mb-0">
                          <span className="block font-taviraj leading-[23.88px]">
                            Date :
                          </span>
                          <span className="block font-light leading-[17.5px]">
                            {item.date}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
            ))}
          </MainSliderMobile>
          <div className="w-full bg-tertiary-600 p-[11px] text-right">
            <Link to="/portfolio" className="inline-block">
              <span className="text-neutral-100 text-16 leading-[20px]">{`More Portfolio >>`}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withDrawer(HomePortfolio);
