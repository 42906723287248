import React, { memo } from "react";
import Layout from "components/Layout";
import FormHeader from "components/Section/FormHeader";
import BrandForm from "components/Section/BrandForm";

const BranchFormPage = () => {
  return (
    <Layout selectedMenu="Form">
      <FormHeader headText="Unleash Your Brand Potential" title="Brand Form" />
      <BrandForm />
    </Layout>
  );
};

export default memo(BranchFormPage);
