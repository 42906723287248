import React, { memo } from "react";
import { ButtonPrimary } from "components/Button";
import { Link } from "react-router-dom";

const CardService = ({ data, ...props }) => {
  return (
    <>
      <div className="w-full relative overflow-hidden">
        <div className="relative">
          <img
            src={`/images/thumb/${data.image}`}
            alt={data.image}
            className="w-full z-[0] object-cover object-center block aspect-square"
            loading="lazy"
          />
        </div>
        <div className="service-thumb-overlay absolute w-full h-full top-0 left-0 z-[1]"></div>
        <div className="absolute w-full h-full z-[2] top-0 left-0 flex justify-center items-center">
          <h4 className="font-taviraj text-24 leading-[20px] text-neutral-100">
            {data.name}
          </h4>
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 m-auto z-[2] w-full h-full flex items-end justify-center cursor-pointer"
          onClick={() => props.handleOpenDrawer(data.id)}
        >
          <i
            className="ico-chevron-up relative bottom-20"
            style={{
              backgroundImage: 'url("/images/icon/ic-double-chevron-up.svg")',
            }}
          ></i>
        </div>
        <div
          className={`absolute w-full h-full left-0 z-[4] bg-primary-300 service-overlay-content ${
            props.openDrawer[data.id] ? "open" : ""
          }`}
        >
          <div className="bg-tertiary-400 p-16">
            <div
              className="btn-close cursor-pointer"
              onClick={() => props.handleOpenDrawer(data.id)}
            >
              <img
                src={`/images/icon/ic-close.svg`}
                alt="Service Thumb 1"
                className="w-[24px] h-[24px] block"
                loading="lazy"
              />
            </div>
          </div>
          <div className="py-24 px-16">
            <h4 className="font-taviraj text-24 leading-[40.94px] text-neutral block">
              {data.name}
            </h4>
            <p className="font-light text-neutral text-16 leading-[20px] block mt-8">
              {data.description}
            </p>
            {data.id == 4 && (
              <Link to="https://wa.me/message/IRUPCHIIQWBYH1" target="_blank">
                <ButtonPrimary className="mt-20">Lets do it!</ButtonPrimary>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CardService);
