import React from "react";
import CardSliderTalentDesktop from "components/Cards/CardSliderTalentDesktop";
import { MainSliderDesktop } from "components/Slider";
import { Link } from "react-router-dom";
import { ButtonTextUnderline } from "components/Button";

const HomeTalentDesktop = ({ talents }) => {
  return (
    <>
      <div className="w-full relative pt-[200px] pb-[100px] z-[2]">
        <div className="w-full mx-auto px-[67px] relative z-[1]">
          <h1 className="text-[48px] leading-[38.40px] text-neutral-100 font-taviraj text-center">
            Featured Talent
          </h1>
          <div className="mt-32">
            <div className="w-full max-w-[1234px] mx-auto">
              <div className="">
                <MainSliderDesktop autoPlay={true}>
                  {talents?.map((item, index) => {
                    return (
                      <div className="!block" key={index}>
                        <CardSliderTalentDesktop {...item} />
                      </div>
                    );
                  })}
                </MainSliderDesktop>
                <div className="w-full px-[28px]">
                  <div className="w-full bg-primary-400 h-[80px] flex items-center justify-end px-[40px] py-[12px]">
                    <Link to="/talent" className="block">
                      <ButtonTextUnderline className="!leading-[110%] !text-20">
                        Browse Talent
                      </ButtonTextUnderline>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeTalentDesktop;
