import React, { memo } from "react";

const CardSliderTalentDesktop = (data) => {
  const categoryList = data.category
    .map((item, index) => (
      <div key={index} className="inline-block text-neutral">
        {item}
      </div>
    ))
    .reduce((acc, x) => (acc === null ? [x] : [acc, " / ", x]), null);

  return (
    <>
      <div className="w-full relative pt-[10%]">
        <div className="w-full min-h-[398px] aspect-[3:1] rounded-[1px] overflow-hidden relative flex items-center">
          <img
            src={`/images/thumb/${data.image_pattern}`}
            alt={data.image_pattern}
            className="w-full h-full object-center object-cover relative z-0 image-talent-pattern"
            loading="lazy"
          />
          <div className="absolute w-full h-full top-0 left-0">
            <div className="block px-[58px] py-24">
              <div className="flex justify-between">
                <div className="">
                  <h3 className="font-taviraj text-[32px] leading-normal text-neutral-50 info-talent-title">
                    {data.name}
                  </h3>
                  <span className="font-medium text-16 leading-[20px] text-tertiary-500 block info-talent-category">
                    {categoryList}
                  </span>
                </div>

                <div className="flex justify-center info-talent-socmed">
                  <div className="flex items-center mr-[15px] last:mr-0">
                    <img
                      src="/images/icon/ic-ig2.svg"
                      alt="icon ig"
                      className="w-[30px] h-[30px] shrink-0"
                      loading="lazy"
                    />
                    <span className="text-[24px] leading-[30px] block pl-[4px] text-neutral-100 whitespace-nowrap">
                      {data.instagram_follower}{" "}
                      <small className="text-[14px] leading-[17.5px]">
                        Followers
                      </small>
                    </span>
                  </div>
                  <div className="flex items-center mr-[15px] last:mr-0">
                    <img
                      src="/images/icon/ic-tiktok2.svg"
                      alt="icon ig"
                      className="w-[30px] h-[30px] shrink-0"
                      loading="lazy"
                    />
                    <span className="text-[24px] leading-[30px] block pl-[4px] text-neutral-100 whitespace-nowrap">
                      {data.tiktok_follower}{" "}
                      <small className="text-[14px] leading-[17.5px]">
                        Followers
                      </small>
                    </span>
                  </div>
                </div>
              </div>
              {data?.vip && (
                <img
                  src="/images/icon/ic-vip.svg"
                  alt=""
                  className="absolute bottom-24 left-[50px] info-talent-vip"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[85px]"></div>
        <div className="absolute w-full h-full m-auto bottom-0 left-0 right-[10%] z-0 max-w-[400px] flex items-end">
          <img
            src={`/images/thumb/${data.image_talent}`}
            alt={data.image_talent}
            className="w-full h-full max-w-[430px] max-h-[632px] object-center object-cover relative z-0 max-w-[400px] mx-auto image-talent"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default memo(CardSliderTalentDesktop);
