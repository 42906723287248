import React, { memo } from "react";
import Layout from "components/Layout";
import AboutIntro from "components/Section/AboutIntro";
import AboutJourney from "components/Section/AboutJourney";
import AboutTeam from "components/Section/AboutTeam";
import AboutPartnership from "components/Section/AboutPartnership";
import FormChoice from "components/Section/FormChoice";

const AboutPage = () => {
  return (
    <Layout selectedMenu="About">
      <div className="w-full relative">
        <AboutIntro />
        <AboutJourney />
        <AboutTeam />
        <AboutPartnership />
        <FormChoice />
      </div>
    </Layout>
  );
};

export default memo(AboutPage);
