import React, { memo } from "react";
import { ButtonPrimary } from "components/Button";
import { Link } from "react-router-dom";

const CardServiceDesktop = ({ data, ...props }) => {
  return (
    <div className="w-full relative overflow-hidden group">
      <div className="relative">
        <img
          src={`/images/thumb/${data.image}`}
          alt={data.image}
          className="w-full z-[0] object-cover object-center block aspect-square"
          loading="lazy"
        />
      </div>
      <div className="service-thumb-overlay absolute w-full h-full top-0 left-0 z-[1]"></div>
      <div className="absolute w-full left-0 z-[2] transition-all duration-500 bottom-[-210px] group-hover:bottom-0">
        <div className="relative w-full z-[2] bottom-0 left-0 flex items-center px-[27px] py-16 bg-overlay-card-service-text">
          <h4 className="font-taviraj text-24 leading-[20px] text-neutral-100">
            {data.name}
          </h4>
        </div>
        <div className="relative w-full h-[212px] bg-primary-500 border-t-[2px] border-tertiary-500 py-16 px-24 left-0 z-[3]">
          <div className="flex flex-col justify-between h-full">
            <p className="font-light text-neutral text-16 leading-[20px] block">
              {data.description}
            </p>
            {data.id == 4 && data.whatsapp && (
              <Link to="https://wa.me/message/IRUPCHIIQWBYH1" target="_blank">
                <ButtonPrimary className="mt-20 inline-block">
                  Lets do it!
                </ButtonPrimary>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardServiceDesktop);
