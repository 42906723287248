import React, { memo } from "react";
import CardServiceDesktop from "components/Cards/CardServiceDesktop";

const HomeServicesDesktop = ({ services }) => {
  return (
    <div className="">
      <div className="block md:flex">
        {services?.list.map((item, index) => {
          return (
            <div className="w-full md:w-1/4" key={index}>
              <CardServiceDesktop data={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(HomeServicesDesktop);
