import React, { memo } from "react";
import { MainSliderMobile } from "components/Slider";

const ClientMobile = ({ data }) => {
  return (
    <>
      <MainSliderMobile autoPlay={true} dots={false}>
        {data?.map((item, index) => (
          <div className="relative !block" key={index}>
            <img
              src={`/images/thumb/${item.image}`}
              alt={item.image}
              className="w-full"
              loading="lazy"
            />
          </div>
        ))}
      </MainSliderMobile>
    </>
  );
};

export default memo(ClientMobile);
