import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { ScrollRestoration } from "react-router-dom";

const Layout = ({ noBack = false, menuBig, selectedMenu, ...props }) => {
  return (
    <>
      <div className="mx-auto overflow-hidden">
        <div className="min-h-screen bg-primary-100 relative">
          <Header
            buttonBack={noBack}
            menuBig={menuBig}
            selectedMenu={selectedMenu}
          />
          <main
            className={`pr-[31px] md:pr-0 relative z-[1] ${
              noBack ? "" : "md:pt-[124px]"
            }`}
          >
            {props.children}
          </main>
          <Footer />
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
};

export default Layout;
