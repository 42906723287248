import React from "react";
import faq from "data/Home/faq.json";
import "./style.css";
import withDrawer from "utils/withDrawer";
import CardFaq from "components/Cards/CardFaq";

const HomeFaq = (props) => {
  return (
    <>
      <div className="w-full relative md:max-w-[1096px] md:mx-auto md:px-16 md:pb-[156px] md:pt-[100px]">
        <div className="pt-[11px] pb-[40px] md:py-[32px] px-16">
          <h1 className="text-[64px] md:text-[48px] leading-[51.2px] leading-[48px] text-neutral-100 font-taviraj">
            FaQ
          </h1>
        </div>
        <div className="block px-[15px] pb-[20px]">
          <CardFaq
            data={faq}
            openDrawer={props.openDrawer}
            handleOpenDrawer={props.handleOpenDrawer}
          />
        </div>
      </div>
    </>
  );
};

export default withDrawer(HomeFaq);
