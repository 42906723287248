import React, { memo } from "react";
import formchoice from "data/form-choice.json";
import "./style.css";
import { Link } from "react-router-dom";

const FormChoice = ({ haveBackground = false }) => {
  return (
    <>
      <div
        className="w-full relative md:pt-[100px] md:pb-[168px]"
        id="id-form-choice"
      >
        {haveBackground && (
          <div
            className="w-full h-full absolute top-0 left-0 z-0 bg-primary-300 hidden md:block"
            style={{
              backgroundImage: 'url("/images/pattern-form-choice.png")',
              backgroundRepeat: "repeat",
            }}
          ></div>
        )}
        <div className="relative z-[1]">
          <div className="pt-[20px] pb-[16px] px-16 md:pt-0 text-left md:text-center">
            <h1 className="text-[64px] leading-[51.2px] text-neutral-100 font-taviraj block md:hidden">
              Let's Get to Work!
            </h1>
            <h1 className="text-[48px] leading-[100%] text-neutral-100 font-taviraj hidden md:block">
              Ready to take the next step? <br />
              Contact us now!
            </h1>
            <p className="block mt-[40px] text-[14px] md:text-16 leading-[17.5px] text-[#EBC797]">
              *If you have any questions, you can reach us at{" "}
              <a
                href="mailto:business@eternalpetite.com"
                className="inline-block leading-[23.88px] underline"
              >
                business@eternalpetite.com
              </a>
            </p>
          </div>
          <div className="md:flex md:justify-center md:gap-[128px] md:mt-[76px]">
            {formchoice?.map((item, index) => {
              return (
                <div
                  className="w-full relative min-h-[196px] md:w-[408px] md:h-[408px] form-choice-link-wrapper"
                  key={index}
                >
                  <Link to={item.link} className="form-choice-link">
                    <div className="relative hidden md:block">
                      <div
                        className={`absolute top-0 left-0 w-full py-24 px-32 z-[1] ${
                          index === 0 ? "bg-primary-500" : "bg-tertiary-600"
                        }`}
                      >
                        <h5 className="text-24 leading-[40.94px] text-neutral-100">
                          {item.title}
                        </h5>
                        <p className="text-20 leading-[27px] text-primary-900 m-0 overflow-hidden block form-choice-link-desc">
                          <span className="block">{item.description}</span>
                        </p>
                      </div>
                      <img
                        src={`/images/thumb/${item.image.desktop}`}
                        alt={item.image.mobile}
                        className="w-full object-cover object-center aspect-square md:w-[408px] md:h-[408px]"
                        loading="lazy"
                      />
                    </div>
                    <div className="relative block md:hidden">
                      <img
                        src={`/images/thumb/${item.image.mobile}`}
                        alt={item.image.mobile}
                        className="w-full h-[221px] object-cover object-center block"
                        loading="lazy"
                      />
                      <div className="w-full absolute top-0 left-0 h-full p-16 block">
                        <div className="w-full h-full font-taviraj flex items-center ">
                          <div className="">
                            <h5 className="text-24 leading-[40.94px] text-neutral-50">
                              {item.title}
                            </h5>
                            <span className="text-16 leading-[27px] text-primary-900">
                              {item.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(FormChoice);
