import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";

const MainSliderMobile = ({
  dots,
  children,
  beforeChange,
  autoPlay,
  className,
}) => {
  var settings = {
    dots: dots ?? true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay ?? false,
    pauseOnHover: false,
    autoplaySpeed: 3500,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      if (beforeChange) beforeChange(current, next);

      return;
    },
  };

  return (
    <>
      <Slider {...settings} className={`main-slider ${className ?? ""}`}>
        {children}
      </Slider>
    </>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        width: "22px",
        height: "37px",
        backgroundImage: 'url("/images/icon/ic-arrow-right-pink.svg")',
        right: "-50px",
        top: "60%",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        width: "22px",
        height: "37px",
        backgroundImage: 'url("/images/icon/ic-arrow-left-pink.svg")',
        transformOrigin: "center",
        left: "-50px",
        top: "60%",
      }}
      onClick={onClick}
    />
  );
};

const MainSliderDesktop = ({
  dots,
  arrows,
  children,
  beforeChange,
  autoPlay,
  className,
}) => {
  var settings = {
    dots: dots ?? true,
    infinite: true,
    arrows: arrows ?? true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay ?? false,
    pauseOnHover: false,
    autoplaySpeed: 8000,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    fade: true,
    beforeChange: (current, next) => {
      if (beforeChange) beforeChange(current, next);

      return;
    },
  };

  return (
    <>
      <Slider
        {...settings}
        className={`main-slider-desktop ${className ?? ""}`}
      >
        {children}
      </Slider>
    </>
  );
};

export { MainSliderMobile, MainSliderDesktop };
