import React, { useState } from "react";
import services from "data/Home/services.json";
import "./style.css";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";
import HomeServiceDesktop from "./HomeServiceDesktop";
import HomeServiceMobile from "./HomeServiceMobile";

const HomeServices = (props) => {
  return (
    <div className="w-full md:max-w-[1328px] md:mx-auto md:px-16 relative z-10 md:mt-[-15%]">
      <div className="w-full relative">
        <div className="py-[40px] px-16 md:p-0">
          <h1 className="text-[64px] md:text-[48px] leading-[51.2px] md:leading-normal text-neutral-100 font-taviraj">
            {services.title}
          </h1>
        </div>
        {props.windowDimension.width > MAX_RESPONSIVE_MOBILE ? (
          <HomeServiceDesktop services={services} />
        ) : (
          <HomeServiceMobile services={services} />
        )}
      </div>
    </div>
  );
};

export default withDimension(HomeServices);
