import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import PortfolioList from "components/Section/PortfolioList";
import FormChoice from "components/Section/FormChoice";
import { Link } from "react-router-dom";
import { ButtonOutline } from "components/Button";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";

const PortfolioPage = (props) => {
  const [calcContainer, setCalcContainer] = useState(0);

  useEffect(() => {
    let calculate = (props.windowDimension.width - 1056) / 2;
    let exactContainer = calculate + 1056 + "px";

    setCalcContainer(exactContainer);
  }, [props, props.windowDimension.width]);

  return (
    <Layout selectedMenu="Portfolio">
      <div className="w-full relative">
        <div className="md:h-[285px] md:flex md:items-center md:justify-center md:mb-[100px] md:bg-primary-300 md:relative">
          <div
            className="w-full h-full absolute top-0 left-0 z-0 hidden md:block"
            style={{
              backgroundImage: 'url("/images/bg-about-pattern.png")',
              background: 'url("/images/bg-about-pattern.png"), #280D02',
              backgroundBlendMode: "soft-light, normal",
            }}
          ></div>
          <div
            className={`w-full md:h-full md:flex md:items-center ml-auto mr-0 md:pl-16 md:flex relative z-[1]`}
            style={{
              maxWidth:
                props.windowDimension.width > MAX_RESPONSIVE_MOBILE
                  ? calcContainer
                  : "auto",
            }}
          >
            <div className="md:w-3/5">
              <div className="py-[40px] px-16 md:p-0">
                <div className="md:flex md:gap-[56px] md:pr-[26px]">
                  <h1 className="text-[64px] md:text-[48px] leading-[51.2px] md:leading-[38.40px] text-neutral-100 font-taviraj">
                    Our Portfolio
                  </h1>
                  <p className="font-light text-[20px] text-primary-800 block mt-[20px] md:mt-0">
                    We have completed over 200+ projects since 2020. Here are
                    some of the amazing campaign we have accomplished.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-2/5 w-full h-full relative">
              <img
                src={`/images/thumb/thumb-portfolio-main-desktop.jpg`}
                alt="image thumb banner portfolio"
                className="w-full h-full absolute object-center object-cover z-0 right-0"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <PortfolioList />
      </div>
      <div className="block md:flex md:justify-center pt-[40px] md:pt-[72px] pb-[20px] md:pb-[72px] flex items-center gap-[24px] md:gap-[40px] px-[16px]">
        <Link to="/talent" className="block">
          <ButtonOutline>Talent</ButtonOutline>
        </Link>
        <Link to="/about" className="block">
          <ButtonOutline>About Us</ButtonOutline>
        </Link>
      </div>
      <FormChoice />
    </Layout>
  );
};

export default withDimension(PortfolioPage);
