import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/404";
import Home from "./pages/home";
import Portfolio from "./pages/portfolio";
import Talent from "pages/talent";
import About from "pages/about";
import BrandForm from "pages/brand-form";
import TalentFormPage from "pages/talent-form";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/talent",
    element: <Talent />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/brand-form",
    element: <BrandForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/talent-form",
    element: <TalentFormPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <div className="">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
