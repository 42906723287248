import React from "react";
import Layout from "components/Layout";
import HomeBanner from "components/Section/HomeBanner";
import HomeServices from "components/Section/HomeServices";
import HomePortfolioMobile from "components/Section/HomePortfolio/HomePortfolioMobile";
import HomeTalentMobile from "components/Section/HomeTalent/HomeTalentMobile";
import HomeClient from "components/Section/HomeClient";
import HomeFaq from "components/Section/HomeFaq";
import FormChoice from "components/Section/FormChoice";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";
import HomePortfolioDesktop from "components/Section/HomePortfolio/HomePortfolioDesktop";
import HomeTalentDesktop from "components/Section/HomeTalent/HomeTalentDesktop";
import portfolio from "data/Home/portfolio.json";
import talents from "data/Home/talent.json";

const Home = (props) => {
  return (
    <Layout noBack selectedMenu="Home">
      <HomeBanner />
      <HomeServices />
      {props.windowDimension.width <= MAX_RESPONSIVE_MOBILE ? (
        <>
          <HomePortfolioMobile portfolio={portfolio} />
          <HomeTalentMobile talents={talents} />
        </>
      ) : (
        <>
          <HomeTalentDesktop talents={talents} />
          <HomePortfolioDesktop portfolio={portfolio} />
        </>
      )}
      <HomeClient />
      <HomeFaq />
      <FormChoice haveBackground />
    </Layout>
  );
};

export default withDimension(Home);
