import React, { memo } from "react";

const FormHeader = ({ title, headText }) => {
  return (
    <div className="max-w-[800px] mx-auto px-16">
      {headText && (
        <div className="pt-32 pb-[64px] block md:hidden">
          <h1 className="font-taviraj text-neutral-100 text-[72px] leading-[57.6px] mb-[18px]">
            {headText}
          </h1>
        </div>
      )}
      <div className="hidden md:block mb-[64px]">
        <img
          src="/images/main-logo@2x.png"
          alt="Main logo"
          className="w-[145px] h-[88.26px]"
          loading="lazy"
        />
      </div>
      <div className="mb-32">
        <h3 className="font-taviraj text-primary-900 text-32 md:text-[48px] leading-normal md:leading-[38.4px]">
          {title}
        </h3>
        <div className="w-full h-[1px] bg-primary-600 my-32 hidden md:block"></div>
        <span className="block text-14 leading-[18px] text-primary-800">
          *If you have any questions, you can reach us at{" "}
          <a href="mailto:business@eternalpetite.com" className="">
            business@eternalpetite.com
          </a>
        </span>
      </div>
    </div>
  );
};

export default memo(FormHeader);
