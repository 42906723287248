import { useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import { createWrapperAndAppendToBody } from "helper";

const ReactPortal = ({ children, wrapperId = "portal-wrapper" }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }

    setWrapperElement(element);

    return () => {
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, document.getElementById(wrapperId));
};

export default ReactPortal;
