import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const InstagramFeed = () => {
  const [dataProfile, setDataProfile] = useState({});

  const iconOpenNewTab = "/images/icon/ic-link-new-tab.svg";
  const iconReels = "/images/icon/ic-video-reels.svg";

  useEffect(() => {
    getInstagramData().then((res) => {
      setDataProfile(res);
    });
  }, []);

  return (
    <>
      <div className="block w-full">
        <div className="flex flex-wrap">
          {dataProfile?.data?.slice(0, 4).map((item) => (
            <div className="w-1/2" key={item.id}>
              <Link
                to={item.permalink}
                target="_blank"
                className="w-full h-full relative pb-[100%] block"
              >
                <img
                  src={
                    item.media_type === "VIDEO"
                      ? item.thumbnail_url
                      : item.media_url
                  }
                  alt={item.caption ?? "Image Feed"}
                  className="w-full h-full object-cover object-center absolute z-0"
                  loading="lazy"
                />
                <img
                  src={item.media_type === "VIDEO" ? iconReels : iconOpenNewTab}
                  alt=""
                  className="absolute w-[16px] h-[16px] top-4 right-4 z-1"
                  loading="lazy"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const getInstagramData = async () => {
  // const token =
  //   "IGQVJYdzYtTWJlZAzNoUHJ2SnU2NDJWOF85NHFrWmRnWDZAvTHVWUzc1SHlSYlZAKcHZAHbUdxNkdfYmtCWkNYSmNVZAnRGQ1gtVjFFU2gxUDlPU0JvcS0zbDc1Ums3Rk1SQnlTWF84UlZAnYzRWSzJ3U3JuTwZDZD";
  const token =
    "IGQVJVLTJxVmJRS2pOMzIyQkNSSkMwdzRJZAWZALTTlxV2JwUmFJX3RudmZAIbHJwdmRPLUpKZAkc1SXVMekJ2QW5Xc09WTV9mc29jUnJJX1VQazQ1dzZAIeW14THJ6MFBaQUpXV0hPa3h4TzI4X3g0WlhFcgZDZD";
  const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,thumbnail_url,timestamp,media_types,permalink&access_token=${token}`;

  const data = await fetch(url);
  const feed = await data.json();

  const promise = new Promise((resolve, reject) => {
    resolve(feed);
    reject(new Error("Cant get the data"));
  });

  return promise;
};

export default memo(InstagramFeed);
