import React, { useState } from "react";

const withDrawer = (OriginalComponent) => {
  return (props) => {
    const [openDrawer, setOpenDrawer] = useState({});

    const handleOpenDrawer = (id) => {
      setOpenDrawer((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    };

    return (
      <OriginalComponent
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        handleOpenDrawer={handleOpenDrawer}
        {...props}
      />
    );
  };
};

export default withDrawer;
