import React from "react";
import { MainSliderMobile } from "components/Slider";
import CardSliderDrawerMobile from "components/Cards/CardSliderDrawerMobile";
import withDrawer from "utils/withDrawer";

const SliderList = ({ id, list, handleBeforeChange, handleOpenDrawer }) => {
  return (
    <MainSliderMobile
      beforeChange={handleBeforeChange}
      className="portfolio-slider"
    >
      {list.map((el, index) => (
        <div
          className="w-full pb-[100%] relative !block bg-neutral-600"
          key={index}
          onClick={() => handleOpenDrawer(id)}
        >
          <img
            src={`/images/thumb/${el.thumbnail}`}
            alt={el.thumbnail}
            className="w-full h-full absolute object-center object-cover z-0"
            loading="lazy"
          />
        </div>
      ))}
    </MainSliderMobile>
  );
};

const ListMobile = (props) => {
  const handleBeforeChange = (current, next) => {
    props.setOpenDrawer((prevState) => {
      const updatedState = {};

      Object.keys(prevState).forEach((key) => {
        updatedState[key] = false;
      });

      return updatedState;
    });
  };

  return (
    <>
      {props.data?.map((item, index) => {
        return (
          <CardSliderDrawerMobile
            key={index}
            id={index}
            customThumbnail={
              <SliderList
                id={index}
                list={item.list}
                handleBeforeChange={handleBeforeChange}
                handleOpenDrawer={props.handleOpenDrawer}
              />
            }
            areaClickDrawer="button"
            logo={item.logo}
            openDrawer={props.openDrawer}
            handleOpenDrawer={props.handleOpenDrawer}
            contentDrawer={
              <>
                <div className="text-14 text-neutral">
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-[23.88px]">
                      Brand :
                    </span>
                    <span className="block font-light leading-[17.5px]">
                      {item.brand}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-[23.88px]">
                      Service Type :
                    </span>
                    <span className="block font-light leading-[17.5px]">
                      {item.service_type}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-[23.88px]">
                      Talent :
                    </span>
                    <span className="block font-light leading-[17.5px]">
                      {item.talent}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-[23.88px]">
                      Platform :
                    </span>
                    <span className="block font-light leading-[17.5px]">
                      {item.platform}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-[23.88px]">
                      Date :
                    </span>
                    <span className="block font-light leading-[17.5px]">
                      {item.date}
                    </span>
                  </div>
                </div>
              </>
            }
          />
        );
      })}
    </>
  );
};

export default withDrawer(ListMobile);
