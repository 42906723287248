import React from "react";
import "./style.css";

const CardSliderTalentMobile = (data) => {
  const categoryList = data.category
    .map((item, index) => (
      <div key={index} className="inline-block text-neutral">
        {item}
      </div>
    ))
    .reduce((acc, x) => (acc === null ? [x] : [acc, " / ", x]), null);

  return (
    <>
      <div className="w-full relative block h-full">
        <div className="block">
          <img
            src={`/images/thumb/${data.image}`}
            alt={data.image}
            className="w-full h-full block relative z-0 object-cover object-center"
            loading="lazy"
          />
          <div className="absolute w-full top-0 left-0 z-[1]">
            <div className="overlay-top-card-talent absolute w-full h-full z-0"></div>
            <div className="px-[19px] pt-[16px] pb-[20px] flex justify-between relative z-[1]">
              <div className="">
                <h3 className="font-taviraj text-[32px] leading-[54.59px] text-neutral-50">
                  {data.name}
                </h3>
                <span className="font-medium text-16 leading-[20px] text-tertiary-500">
                  {categoryList}
                </span>
              </div>
              {data?.vip && (
                <img
                  src="/images/icon/ic-vip.svg"
                  alt=""
                  className="shrink-0"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
        <div className="block bg-tertiary-400 py-[27px] px-[5px]">
          <div className="flex justify-center">
            <div className="flex items-center mr-[3%] last:mr-0">
              <img
                src="/images/icon/ic-ig2.svg"
                alt="icon ig"
                className="w-[30px] h-[30px] shrink-0"
                loading="lazy"
              />
              <span className="text-[24px] leading-[30px] block pl-[4px]">
                {data.instagram_follower}{" "}
                <small className="text-[14px] leading-[17.5px]">
                  Followers
                </small>
              </span>
            </div>
            <div className="flex items-center mr-[3%] last:mr-0">
              <img
                src="/images/icon/ic-tiktok2.svg"
                alt="icon ig"
                className="w-[30px] h-[30px] shrink-0"
                loading="lazy"
              />
              <span className="text-[24px] leading-[30px] block pl-[4px]">
                {data.tiktok_follower}{" "}
                <small className="text-[14px] leading-[17.5px]">
                  Followers
                </small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CardSliderTalentMobile };
