import React, { memo, useState, useRef, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = ({ buttonBack, menuBig, selectedMenu }) => {
  const [collapseNavbarMobile, setCollapseNavbarMobile] = useState(true);
  const [openNavbar, setOpenNavbar] = useState(false);
  let menuRef = useRef();

  const handleMenu = () => {
    setCollapseNavbarMobile(!collapseNavbarMobile);
  };

  const handleNavbar = () => {
    setOpenNavbar(!openNavbar);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpenNavbar(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [menuRef]);

  return (
    <>
      <div className="hidden md:block absolute top-0 left-0 w-full z-10">
        <div className="flex items-center justify-between p-[32px]">
          {!buttonBack && (
            <Link to="/" className="block">
              <img
                src="/images/icon/ic-back-header.svg"
                alt="icon back header"
                className=""
                loading="lazy"
              />
            </Link>
          )}
          <div
            className={`fixed w-[36px] h-[36px] flex items-center justify-center z-10 ${
              menuBig ? "right-[34px] top-[100px]" : "right-[32px] top-[32px]"
            }`}
          >
            <div
              id="nav-icon4"
              className={openNavbar ? "open" : ""}
              onClick={handleNavbar}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div
            className={`fixed h-full min-w-[128px] px-20 top-0 right-0 bg-primary-900 z-20 flex items-center navbar ${
              openNavbar ? "open" : ""
            }`}
            ref={menuRef}
          >
            <div className="container-navbar w-full">
              <ul className="text-center text-20 text-primary-500 flex flex-col vertical-gap-menu">
                <li
                  className={`transition-all duration-500 font-regular text-primary-500 hover:font-bold hover:text-tertiary-600 ${
                    selectedMenu == "Home"
                      ? "!font-bold !text-tertiary-600 pointer-events-none"
                      : ""
                  }`}
                >
                  <Link to="/" className="inline-block">
                    Home
                  </Link>
                </li>
                <li
                  className={`transition-all duration-500 font-regular text-primary-500 hover:font-bold hover:text-tertiary-600 ${
                    selectedMenu == "Talent"
                      ? "!font-bold !text-tertiary-600 pointer-events-none"
                      : ""
                  }`}
                >
                  <Link to="/talent" className="inline-block">
                    Talent
                  </Link>
                </li>
                <li
                  className={`transition-all duration-500 font-regular text-primary-500 hover:font-bold hover:text-tertiary-600 ${
                    selectedMenu == "Portfolio"
                      ? "!font-bold !text-tertiary-600 pointer-events-none"
                      : ""
                  }`}
                >
                  <Link to="/portfolio" className="inline-block">
                    Portfolio
                  </Link>
                </li>
                <li
                  className={`transition-all duration-500 font-regular text-primary-500 hover:font-bold hover:text-tertiary-600 ${
                    selectedMenu == "About"
                      ? "!font-bold !text-tertiary-600 pointer-events-none"
                      : ""
                  }`}
                >
                  <Link to="/about" className="inline-block">
                    About
                  </Link>
                </li>
                <li
                  className={`transition-all duration-500 font-regular text-primary-500 hover:font-bold hover:text-tertiary-600 ${
                    selectedMenu == "Form"
                      ? "!font-bold !text-tertiary-600 pointer-events-none"
                      : ""
                  }`}
                >
                  <HashLink
                    to="#id-form-choice"
                    className="inline-block"
                    preventScrollReset={true}
                  >
                    Form
                  </HashLink>
                </li>
                <li className="">
                  <div className="flex flex-col items-center vertical-gap-menu-socmed">
                    <Link
                      to="https://wa.me/6281245952643"
                      target="_blank"
                      className="inline-block"
                    >
                      <img
                        src="/images/icon/ic-whatsapp.svg"
                        alt="Whatsapp logo"
                        className="w-32 h-32 block"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      to="https://www.instagram.com/petitemanagement/"
                      target="_blank"
                      className="inline-block"
                    >
                      <img
                        src="/images/icon/ic-ig.svg"
                        alt="Instagram logo"
                        className="w-32 h-32 block"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      to="https://www.tiktok.com/@eternalpetite?is_from_webapp=1&sender_device=pc"
                      target="_blank"
                      className="inline-block"
                    >
                      <img
                        src="/images/icon/ic-tiktok.svg"
                        alt="Tiktok logo"
                        className="w-32 h-32 block"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/eternal-petite/"
                      target="_blank"
                      className="inline-block"
                    >
                      <img
                        src="/images/icon/ic-linkedln.svg"
                        alt="Linkedln logo"
                        className="w-32 h-32 block"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed w-[31px] bg-primary-900 top-0 right-0 z-[2] block md:hidden">
        <div
          className="flex items-center justify-center h-[68px]"
          onClick={handleMenu}
        >
          <div id="nav-icon3" className={collapseNavbarMobile ? "open" : ""}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div
          className={`wrapper-navbar-mobile overflow-auto ${
            !collapseNavbarMobile ? "collapse" : ""
          }`}
        >
          <div className="rotate-[90deg]">
            <ul className="navbar-mobile flex item-center font-taviraj">
              <li className={selectedMenu == "Home" ? "active" : ""}>
                <Link to="/" className="inline-block">
                  Home
                </Link>
              </li>
              <li className={selectedMenu == "Portfolio" ? "active" : ""}>
                <Link to="/portfolio" className="inline-block">
                  Portfolio
                </Link>
              </li>
              <li className={selectedMenu == "Talent" ? "active" : ""}>
                <Link to="/talent" className="inline-block">
                  Talent
                </Link>
              </li>
              <li className={selectedMenu == "About" ? "active" : ""}>
                <Link to="/about" className="inline-block">
                  About
                </Link>
              </li>
              <li className={selectedMenu == "Form" ? "active" : ""}>
                <HashLink
                  to="#id-form-choice"
                  className="inline-block"
                  preventScrollReset={true}
                >
                  Form
                </HashLink>
              </li>
              <li className="">
                <div className="w-full h-full flex items-center gap-[18px]">
                  <Link
                    to="https://wa.me/6281245952643"
                    target="_blank"
                    className="block w-24 h-24 rotate-[-90deg]"
                  >
                    <img
                      src="/images/icon/ic-whatsapp.svg"
                      alt="Whatsapp logo"
                      className="w-24 h-24 block"
                      loading="lazy"
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/petitemanagement/"
                    target="_blank"
                    className="block w-24 h-24 rotate-[-90deg]"
                  >
                    <img
                      src="/images/icon/ic-ig.svg"
                      alt="Instagram logo"
                      className="w-24 h-24 block"
                      loading="lazy"
                    />
                  </Link>
                  <Link
                    to="https://www.tiktok.com/@eternalpetite?is_from_webapp=1&sender_device=pc"
                    target="_blank"
                    className="block w-24 h-24 rotate-[-90deg]"
                  >
                    <img
                      src="/images/icon/ic-tiktok.svg"
                      alt="Tiktok logo"
                      className="w-24 h-24 block"
                      loading="lazy"
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/eternal-petite/"
                    target="_blank"
                    className="block w-24 h-24 rotate-[-90deg]"
                  >
                    <img
                      src="/images/icon/ic-linkedln.svg"
                      alt="Linkedln logo"
                      className="w-24 h-24 block"
                      loading="lazy"
                    />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Header);
