import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonTextUnderline } from "components/Button";

const HomePortfolioDesktop = ({ portfolio }) => {
  const [hoveredDiscover, setHoveredDiscovered] = useState(false);

  const toggleHover = () => setHoveredDiscovered(!hoveredDiscover);

  return (
    <div className="relative py-[14px]">
      <div className="bg-[#f5e5cb4d] py-[15px]">
        <div className="bg-primary-900">
          <div className="w-full py-[92px] relative z-[1]">
            <div className="w-full max-w-[1065px] m-auto px-[16px] relative z-[1]">
              <div className="pb-[34px]">
                <h1 className="text-[48px] leading-[38.40px] font-taviraj text-primary-500">
                  Our Portfolio
                </h1>
              </div>
              <div className="flex flex-wrap">
                {portfolio.desktop?.map((item, index) => (
                  <div className="w-1/3 relative" key={index}>
                    <div className="relative after:content-[''] after:bg-portfolio-overlay-desktop after:w-full after:h-full after:absolute after:top-0 after:left-0 after:z-[1]">
                      <img
                        src={`/images/thumb/${item.thumbnail}`}
                        alt={item.thumbnail}
                        className="w-full h-full object-center object-cover relative z-0 aspect-square"
                        loading="lazy"
                      />
                    </div>
                    <div className="absolute w-[72px] h-[72px] bottom-[19px] left-[16px] z-[1]">
                      <img
                        src={`/images/logo/${item.logo}`}
                        alt={item.logo}
                        className="w-full h-full relative object-cover object-center"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
                <div className="w-1/3">
                  <Link
                    to="/portfolio"
                    className="block w-full aspect-square bg-primary-500"
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                  >
                    <div
                      className={`transition-all duration-300 ${
                        hoveredDiscover ? "p-[36px]" : "p-[24px]"
                      }`}
                    >
                      <div className="block w-full h-full aspect-square border-[1px] border-primary-700 flex items-center justify-center">
                        <ButtonTextUnderline
                          className={hoveredDiscover ? "hover" : ""}
                        >
                          Discover more
                        </ButtonTextUnderline>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 z-0 w-full h-full">
            <img
              src="/images/bg-pattern-portfolio.webp"
              alt="image pattern protfolio"
              className="w-full h-full object-center object-cover relative"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HomePortfolioDesktop);
