import React from "react";
import teams from "data/About/team.json";

const AboutTeam = () => {
  return (
    <div className="w-full block py-20 md:py-[170px] relative">
      <div className="max-w-[1112px] mx-auto relative z-[1]">
        <h3 className="font-taviraj px-16 text-[63px] md:text-[48px] leading-[50.4px] md:leading-[38.4px] text-[#FFF5F0] md:text-center">
          The <br className="block md:hidden" /> Team
        </h3>
        <div className="mt-[40px] md:mt-[80px]">
          <div className="flex flex-wrap">
            {teams.map((item, index) => (
              <div className="w-1/2 md:w-1/3 relative" key={index}>
                <div className="w-full aspect-square">
                  <img
                    src={`/images/thumb/${item.profile_picture}`}
                    alt={item.profile_picture}
                    className="w-full object-center object-cover relative z-0"
                    loading="lazy"
                  />
                </div>
                <div className="absolute w-full h-full top-0 left-0 bg-overlay-about-team z-[1] flex items-end justify-center px-8">
                  <div className="pb-8 md:pb-20">
                    <span className="inline-block bg-neutral-100 text-12 md:text-20 leading-[15px] md:leading-[25px] font-medium">
                      {item.position}
                    </span>
                    <h5 className="font-taviraj text-neutral-50 text-16 md:text-24 leading-[27px] md:leading-[41px] tracking-[0.03em]">
                      {item.name}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute w-full aspect-video top-0 bottom-0 my-auto z-0">
        <img
          src={`/images/ornament-about-team.svg`}
          alt="ornament about team"
          className="w-full object-center object-cover relative z-0"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default AboutTeam;
