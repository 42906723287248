import React from "react";
import withDrawer from "utils/withDrawer";
import CardService from "components/Cards/CardService";

const HomeServiceMobile = (props) => {
  return (
    <div className="block md:flex">
      {props.services?.list.map((item, index) => {
        return (
          <div className="w-full md:w-1/4" key={index}>
            <CardService data={item} {...props} />
          </div>
        );
      })}
    </div>
  );
};

export default withDrawer(HomeServiceMobile);
