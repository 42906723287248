import React, { useEffect, useState } from "react";
import { getWindowDimensions } from "helper";

const withDimension = (OriginalComponent) => {
  return (props) => {
    const [windowDimension, setWindowDimension] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      let handleResize = () => {
        setWindowDimension(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return <OriginalComponent windowDimension={windowDimension} {...props} />;
  };
};

export default withDimension;
