import React, { memo } from "react";

const CardSliderDrawerMobile = ({
  id,
  thumbnail,
  logo,
  contentDrawer,
  openDrawer,
  handleOpenDrawer,
  customThumbnail,
  areaClickDrawer = "full",
  customDots,
}) => {
  return (
    <>
      <div className="relative block h-full w-full overflow-hidden">
        <div
          className={`w-full relative bg-neutral-600 ${
            customThumbnail ? "" : "pb-[100%]"
          }`}
        >
          {customThumbnail}
          {typeof thumbnail !== "undefined" && (
            <img
              src={`/images/thumb/${thumbnail}`}
              alt={thumbnail}
              className="w-full h-full absolute object-center object-cover z-0"
              loading="lazy"
            />
          )}
        </div>
        <div className="w-full h-[113px] bg-main-slider-overlay absolute bottom-0 left-0 z-[1]"></div>
        <div className="absolute w-[72px] h-[72px] bottom-[19px] left-[16px] z-[1]">
          <img
            src={`/images/logo/${logo}`}
            alt={logo}
            className="w-full h-full relative object-cover object-center"
            loading="lazy"
          />
        </div>
        <div
          className={`absolute bottom-0 left-0 right-0 m-auto z-[2] flex items-end justify-center ${
            areaClickDrawer === "button"
              ? "w-[45px] h-[45px] m-auto bottom-20 left-0 right-0"
              : "w-full h-full"
          }`}
          onClick={() => handleOpenDrawer(id)}
        >
          <i
            className={`ico-chevron-up relative ${
              areaClickDrawer === "button" ? "bottom-0" : "bottom-20"
            }`}
            style={{
              backgroundImage: 'url("/images/icon/ic-double-chevron-up.svg")',
            }}
          ></i>
        </div>
        {customDots}
        <div
          className={`absolute w-full h-full left-0 z-[4] service-overlay-content overlay-content-slider-mobile ${
            openDrawer[id] ? "open" : ""
          }`}
        >
          <div className="bg-small-overlay-close-drawer relative header-close">
            <button
              className="btn-close p-16 w-full block"
              onClick={() => handleOpenDrawer(id)}
            >
              <img
                src={`/images/icon/ic-close-white.svg`}
                alt="Service Thumb 1"
                className="w-[24px] h-[24px] block"
                loading="lazy"
              />
            </button>
          </div>
          <div className="py-24 px-16 h-full bg-primary-300 relative body-drawer">
            {contentDrawer}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CardSliderDrawerMobile);
