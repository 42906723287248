import React, { memo } from "react";
import DOMPurify from "dompurify";
import Collapse from "components/Collapse";

const CardFaq = ({ data, openDrawer, handleOpenDrawer }) => {
  return (
    <>
      <div className="border-[1px] border-primary-600 py-24 md:py-[56px] px-16 md:px-[40px]">
        <ul className="m-0 faq-list list-decimal pl-[10px] ml-[10px]">
          {data?.map((item, index) => (
            <li
              className="list-decimal mb-24 md:mb-[40px] last:mb-0 text-primary-900 leading-[20px] font-taviraj font-medium pb-16 md:pb-[40px] border-b-[0.5px] border-tertiary-400 last:border-b-0 last:pb-0 mr-[14px]"
              key={index}
            >
              <div
                className="flex justify-between items-start cursor-pointer"
                onClick={() => handleOpenDrawer(index)}
              >
                <h5 className="title block md:text-20">{item.question}</h5>
                <span className="ml-12 hidden md:flex md:items-center md:w-[22px] md:h-[22px]">
                  {openDrawer[index] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="1"
                      viewBox="0 0 24 1"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 1L7.80346e-09 1L0 0L24 0L24 1Z"
                        fill="#C68490"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 10L11 0L10 0L10 10H0L0 11H10L10 22H11L11 11L22 11V10L11 10Z"
                        fill="#C68490"
                      />
                    </svg>
                  )}
                </span>
              </div>
              <Collapse isOpen={openDrawer[index]}>
                <span
                  className="font-quicksand text-14 text-neutral-100 block mt-12 md:mt-24 h-full"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.answer),
                  }}
                ></span>
              </Collapse>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default memo(CardFaq);
