import React from "react";
import "./footer.css";
import footer from "data/footer.json";
import Copyright from "./Copyright";
import InstagramFeed from "components/InstagramFeed";
import FooterContent from "./FooterContent";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";

const Footer = (props) => {
  return (
    <div className="pr-[31px] md:pr-0 relative z-[0] bg-neutral-600 md:bg-neutral-700">
      <div className="md:flex justify-between w-full">
        <div className="w-full py-[28px] px-24 md:pl-[6%] md:pt-[56px] md:pb-[40px] md:flex md:flex-col md:justify-between bg-neutral-700">
          <FooterContent data={footer} />
          {props.windowDimension.width > MAX_RESPONSIVE_MOBILE && <Copyright />}
        </div>
        <div className="min-[768px]:w-[439px] min-[1280px]:w-[344px] min-[768px]:h-[439px] min-[1280px]:h-[344px] shrink-0">
          <InstagramFeed />
        </div>
      </div>
      {props.windowDimension.width <= MAX_RESPONSIVE_MOBILE && <Copyright />}
    </div>
  );
};

export default withDimension(Footer);
