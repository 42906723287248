import React from "react";
import banner from "data/Home/banner.json";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";
import withDimension from "utils/withDimension";

const HomeBanner = (props) => {
  return (
    <>
      <div className="w-full h-full min-h-screen relative">
        <div
          className="w-full h-full absolute top-0 left-0 z-[2]"
          style={{
            backgroundImage: `url(/images/banner/${
              props.windowDimension.width > MAX_RESPONSIVE_MOBILE
                ? banner.image_desktop
                : banner.image
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        <div className="relative z-[2] pt-[47px] px-[26px] md:max-w-[1328px] md:mx-auto md:px-16 md:pt-[73px]">
          <img
            src="/images/main-logo@2x.png"
            alt="Main logo"
            className="w-[184px] md:w-[220px] h-[112px] md:h-[134px]"
            loading="lazy"
          />
          <h5 className="text-16 leading-[20px] md:text-24 md:leading-normal text-primary-900 block mt-8">
            {banner.title}
          </h5>
        </div>
      </div>
    </>
  );
};

export default withDimension(HomeBanner);
