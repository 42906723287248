import React, { memo } from "react";
import Layout from "components/Layout";
import FormHeader from "components/Section/FormHeader";
import TalentForm from "components/Section/TalentForm";

const TalentFormPage = () => {
  return (
    <Layout selectedMenu="Form">
      <FormHeader
        headText="Hi! we’ve been waiting for you."
        title="Talent Form"
      />
      <TalentForm />
    </Layout>
  );
};

export default memo(TalentFormPage);
