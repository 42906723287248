import React, { memo } from "react";

const CardTalent = ({ id, data, openDrawer, handleOpenDrawer }) => {
  const categoryList = data.category
    .map((item, index) => (
      <div key={index} className="inline-block text-neutral">
        {item}
      </div>
    ))
    .reduce((acc, x) => (acc === null ? [x] : [acc, " / ", x]), null);

  return (
    <>
      <div className="relative w-full h-full overflow-hidden">
        <div
          className={`relative z-0 talent-card ${openDrawer[id] ? "open" : ""}`}
        >
          <img
            src={`/images/thumb/${data.image}`}
            alt={data.image}
            className="w-full h-full relative z-0 image-thumb"
            loading="lazy"
            loading="lazy"
          />
          <span className="block bg-neutral-100 rounded-[1px] capitalize text-16 md:text-24 font-medium text-primary-300 absolute top-[8px] left-[8px] px-[1px] z-1">
            {data.type}
          </span>
          <div
            className="w-full h-full absolute top-0 left-0 z-2 flex items-end cursor-pointer area-click"
            onClick={() => handleOpenDrawer(id)}
          >
            <div className="relative w-full">
              <div className="block bg-talent-title-overlay w-full h-[40px] absolute bottom-0 left-0 z-0"></div>
              <h3 className="block font-taviraj text-16 md:text-24 tracking-[0.48px] text-primary-900 relative z-1 px-[8px] py-[3px]">
                {data.name}
              </h3>
              <img
                src="/images/icon/ic-arrow.svg"
                alt="icon arrow"
                className="absolute left-0 right-0 m-auto bottom-[100%] z-2 block md:hidden"
                loading="lazy"
              />
            </div>
          </div>
          <div
            className={`absolute w-full left-0 z-[4] service-overlay-content border-top-drawer ${
              openDrawer[id] ? "open" : ""
            }`}
          >
            <div className="p-[8px] h-full bg-primary-300 relative body-drawer">
              <span className="font-light text-[10px] md:text-16 text-primary-900 block">
                {categoryList}
              </span>
              <div className="flex items-center md:mt-8">
                <img
                  src="/images/icon/ic-ig2.svg"
                  alt="icon ig"
                  className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] shrink-0"
                  loading="lazy"
                />
                <span className="text-[24px] leading-[30px] block pl-[4px] text-neutral">
                  <small className="font-medium inline-block text-[14px] md:text-24 mr-[4px]">
                    {data.instagram_follower}
                  </small>
                  <small className="text-[10px] md:text-[14px]">
                    Followers
                  </small>
                </span>
              </div>
              {data.tiktok_follower && (
                <div className="flex items-center">
                  <img
                    src="/images/icon/ic-tiktok2.svg"
                    alt="icon ig"
                    className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] shrink-0"
                    loading="lazy"
                  />
                  <span className="text-[24px] leading-[30px] block pl-[4px] text-neutral">
                    <small className="font-medium inline-block text-[14px] md:text-24 mr-[4px]">
                      {data.tiktok_follower}
                    </small>
                    <small className="text-[10px] md:text-[14px]">
                      Followers
                    </small>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CardTalent);
