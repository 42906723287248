import React, { memo } from "react";

const Copyright = () => {
  return (
    <>
      <div className="px-24 md:pl-0 pb-16 pt-[32px] bg-neutral-700">
        <span className="text-12 leading-[15px] text-neutral-400 font-light">
          © 2023 CV Eternal Petite. All Rights Reserved.
        </span>
      </div>
    </>
  );
};

export default memo(Copyright);
