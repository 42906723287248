import React, { memo, useState } from "react";
import talents from "data/Talent/list.json";
import CardTalent from "components/Cards/CardTalent";
import withDrawer from "utils/withDrawer";

const TalentList = (props) => {
  return (
    <>
      <div className="pl-[16px]">
        <div className="flex flex-wrap m-[-4px] md:gap-y-[80px]">
          {talents?.map((item, index) => (
            <div className="w-1/2 md:w-1/4" key={index}>
              <div className="w-full p-[4px]">
                <CardTalent
                  id={index}
                  data={item}
                  openDrawer={props.openDrawer}
                  handleOpenDrawer={props.handleOpenDrawer}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default withDrawer(TalentList);
