import React, { memo, useState } from "react";
import TextInput from "components/Form/TextInput";
import { useForm } from "react-hook-form";
import SelectComponent from "components/Form/Select";
import { ButtonPink } from "components/Button";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import emailjs from "@emailjs/browser";

const talentOptions = [
  { value: "Model / Muse", label: "Model / Muse" },
  { value: "Endorsement", label: "Endorsement" },
  { value: "Live Host", label: "Live Host" },
];

const exclusiveTalentOptions = [
  { value: "No, I am not interested", label: "No, I am not interested" },
  {
    value: "Yes, I want to become Exclusive Talent!",
    label: "Yes, I want to become Exclusive Talent!",
  },
];

const paymentOptions = [
  { value: "BCA", label: "BCA" },
  { value: "DANA", label: "DANA" },
];

const defaultValues = {
  fullname: "",
  nickname: "",
  birth_date: "",
  address: "",
  nationality: "",
  whatsapp_number: "",
  emergency_contact: "",
  username_instagram: "",
  followers_instagram: "",
  username_tiktok: "",
  followers_tiktok: "",
  talent: "",
  exclusive_talent: "",
  id_card: "",
  npwp: "",
  payment: "",
  bank_number: "",
};

const BrandForm = () => {
  const { register, handleSubmit, control } = useForm({ defaultValues });
  const [isLoading, setIsLoading] = useState(false);
  const [completeFillForm, setCompleteFillForm] = useState(false);

  const sendData = async (data) => {
    setIsLoading(true);

    let formData = data;

    formData.fullname = data.fullname;
    formData.nickname = data.nickname;
    formData.birth_date = data.birth_date;
    formData.address = data.address;
    formData.nationality = data.nationality;
    formData.whatsapp_number = data.whatsapp_number;
    formData.emergency_contact = data.emergency_contact;
    formData.username_instagram = data.username_instagram;
    formData.followers_instagram = data.followers_instagram;
    formData.username_tiktok = data.username_tiktok;
    formData.followers_tiktok = data.followers_tiktok;
    formData.talent = data.talent;
    formData.exclusive_talent = data.exclusive_talent;
    formData.id_card = data.id_card;
    formData.npwp = data.npwp;
    formData.payment = data.payment;
    formData.bank_number = data.bank_number;

    await emailjs
      .send(
        "service_i2nu1lv",
        "template_xyzi73c",
        formData,
        "xsgLO8Y31LdVFPlzv"
      )
      .then(
        (result) => {
          setIsLoading(false);
          setCompleteFillForm(true);
          console.log(result.text);
        },
        (error) => {
          setIsLoading(false);
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="w-full block relative z-[100] pb-[80px] md:pb-[200px]">
        <div className="max-w-[800px] mx-auto px-16">
          <form onSubmit={handleSubmit(sendData)}>
            <div className="">
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Full Name
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "fullname",
                    type: "text",
                    placeholder: "Your name",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Nickname
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "nickname",
                    type: "text",
                    placeholder: "Nickname",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Place and date of birth
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "birth_date",
                    type: "text",
                    placeholder: "Jakarta, 01 January 2023",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Complete address
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "address",
                    type: "text",
                    placeholder: "*include City, State and ZIP code",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Nationality
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "nationality",
                    type: "text",
                    placeholder: "Indonesia",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Whatsapp Number
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "whatsapp_number",
                    type: "text",
                    placeholder: "+628123456789",
                    formatRegex: /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/,
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Emergency contact person
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "emergency_contact",
                    type: "text",
                    placeholder: "*parents or partner or friends",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Username Instagram
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "username_instagram",
                    type: "text",
                    placeholder: "@eternalpetite",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Followers on Instagram
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "followers_instagram",
                    type: "text",
                    placeholder: "2,493,293",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Username Tiktok
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "username_tiktok",
                    type: "text",
                    placeholder: "@eternalpetite",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Followers on Tiktok
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "followers_tiktok",
                    type: "text",
                    placeholder: "2,493,293",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  What talent are you?
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "talent",
                    options: talentOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Interested in being Exclusive Talent?
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "exclusive_talent",
                    options: exclusiveTalentOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
                <small className="block text-12 md:leading-14 leading-[15px] md:leading-[18px] text-neutral-300 mt-12">
                  *to become an exclusive talent, you’re required to collaborate
                  within minimum 3-month contract.
                </small>
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  ID Card / KTP
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "id_card",
                    type: "text",
                    placeholder: "*NIK Number",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  No NPWP
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "npwp",
                    type: "text",
                    placeholder: "*optional",
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  Payment
                </label>
                <SelectComponent
                  {...{
                    control,
                    name: "payment",
                    options: paymentOptions,
                    placeholder: "(choose one)",
                    className: completeFillForm ? "disabled" : "",
                  }}
                />
              </div>
              <div className="mb-24 last:mb-0">
                <label className="block text-primary-900 font-taviraj text-16 md:text-20 leading-[27px] md:leading-[34px] mb-[6px] md:mb-[8px]">
                  BCA Account Number / DANA number
                </label>
                <TextInput
                  {...{
                    control,
                    register,
                    name: "bank_number",
                    type: "text",
                    placeholder: "0123-4567-89",
                    formatRegex: /^[0-9\b]+$/,
                    className: completeFillForm
                      ? "!bg-[#17181A] text-neutral-350 pointer-events-none disabled"
                      : "",
                    disabled: completeFillForm ? true : false,
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center mt-[40px] md:mt-[80px] md:max-w-[312px] mx-auto">
              <ButtonPink
                type="submit"
                value="send"
                className={`${
                  completeFillForm
                    ? "text-primary-900 border-[#BB8A3B] !bg-primary-500"
                    : ""
                }`}
                disabled={completeFillForm ? true : false}
              >
                {completeFillForm ? "Form has been submitted." : "Sent"}
              </ButtonPink>
              {completeFillForm && (
                <span className="mt-16 text-neutral-100 text-14 leading-[18px]">
                  *Thank you for choosing us to collaborate :) *We will contact
                  you via WhatsApp around 10 work days{" "}
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading..."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 10000,
            overflow: isLoading ? "hidden" : "scroll",
          }),
        }}
      ></LoadingOverlay>
    </>
  );
};

export default memo(BrandForm);
