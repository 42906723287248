import React from "react";

const AboutJourney = () => {
  return (
    <>
      <div className="block relative py-[20px] md:pt-[140px] px-[16px] font-taviraj">
        <div className="max-w-[784px] mx-auto">
          <h2 className="text-32 md:text-[49px] text-neutral-100 leading-[55px] md:leading-[39.2px] tracking-[0.96px] mb-24 md:mb-[80px] md:pl-[8%]">
            Our Journey
          </h2>
          <div className="text-16 text-neutral-50 leading-[27px] pl-[20px]">
            <div className="pb-32 md:pb-[78px] last:pb-0 relative pl-[35px] before:content-[''] before:w-[1px] before:h-full before:absolute before:bg-[#87562E] before:left-[9.5px] before:top-[13px] last:before:hidden">
              <div className="w-20 h-20 rounded-full border-[1px] border-[#BB8A3B] flex items-center justify-center absolute top-[6px] left-0">
                <div className="w-[5px] h-[5px] bg-primary-600 rotate-[-45deg]"></div>
              </div>
              <div className="">
                <span className="block text-20 leading-[34px]">2020</span>
                <ul className="">
                  <li className="">Eternal Petite founded.</li>
                  <li className="">
                    We offer an{" "}
                    <span className="text-primary-800">
                      Influencer Marketing
                    </span>{" "}
                    service to the industry.
                  </li>
                </ul>
              </div>
            </div>
            <div className="pb-32 md:pb-[78px] last:pb-0 relative pl-[35px] before:content-[''] before:w-[1px] before:h-full before:absolute before:bg-[#C3905B] before:left-[9.5px] before:top-[13px] last:before:hidden">
              <div className="w-20 h-20 rounded-full border-[1px] border-[#DAAB51] flex items-center justify-center absolute top-[6px] left-0">
                <div className="w-[5px] h-[5px] bg-primary-600 rotate-[-45deg]"></div>
              </div>
              <div className="">
                <span className="block text-20 leading-[34px]">2021</span>
                <ul className="">
                  <li className="">
                    We have reached 1000 collaborations with influencers & work
                    with 20 outstanding brands.
                  </li>
                </ul>
              </div>
            </div>
            <div className="pb-32 md:pb-[78px] last:pb-0 relative pl-[35px] before:content-[''] before:w-[1px] before:h-full before:absolute before:bg-[#EBC797] before:left-[9.5px] before:top-[13px] last:before:hidden">
              <div className="w-20 h-20 rounded-full border-[1px] border-[#E8C77A] flex items-center justify-center absolute top-[6px] left-0">
                <div className="w-[5px] h-[5px] bg-primary-600 rotate-[-45deg]"></div>
              </div>
              <div className="">
                <span className="block text-20 leading-[34px]">2022</span>
                <ul className="">
                  <li className="">
                    Formalizing the company as{" "}
                    <span className="text-primary-800">CV. Eternal Petite</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pb-32 md:pb-[78px] last:pb-0 relative pl-[35px] before:content-[''] before:w-[1px] before:h-full before:absolute before:bg-[#87562E] before:left-[9.5px] before:top-[13px] last:before:hidden">
              <div className="w-20 h-20 rounded-full border-[1px] border-primary-100 flex items-center justify-center absolute top-[6px] left-0 bg-[#E8C77A]">
                <div className="w-[5px] h-[5px] bg-primary-600 rotate-[-45deg] hidden"></div>
              </div>
              <div className="">
                <span className="block text-20 leading-[34px]">2023</span>
                <ul className="">
                  <li className="">
                    2 new services;
                    <span className="text-primary-800">
                      {" "}
                      Content Creation
                    </span>{" "}
                    & <span className="text-primary-800">Website Creation</span>
                    .
                  </li>
                  <li className="">
                    Currently, we have started collaborating with several brands
                    in Southeast Asia.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[929px] mx-auto px-16 text-[#FFF9F6] mt-[43px] md:mt-[150px]">
          <div className="relative">
            <div className="w-full font-taviraj text-18 text-primary-600 overflow-hidden">
              <div className="whitespace-nowrap">
                ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              </div>
            </div>
            <div className="flex justify-center md:gap-[104px] py-[20px] md:py-[40px]">
              <div className="text-center w-1/3 md:w-auto">
                <h5 className="font-taviraj text-20 md:text-32 leading-[30px]">
                  2000+
                </h5>
                <span className="font-quicksand block text-[10px] md:text-20 leading-[12px] md:leading-[25px] mt-8 md:mt-12">
                  Talent
                </span>
              </div>
              <div className="text-center w-1/3 md:w-auto">
                <h5 className="font-taviraj text-20 md:text-32 leading-[30px]">
                  500+
                </h5>
                <span className="font-quicksand block text-[10px] md:text-20 leading-[12px] md:leading-[25px] mt-8 md:mt-12">
                  Collaboration
                </span>
              </div>
              <div className="text-center w-1/3 md:w-auto">
                <h5 className="font-taviraj text-20 md:text-32 leading-[30px]">
                  3 Years
                </h5>
                <span className="font-quicksand block text-[10px] md:text-20 leading-[12px] md:leading-[25px] mt-8 md:mt-12">
                  Since 2020
                </span>
              </div>
            </div>
            <div className="w-full font-taviraj text-18 text-primary-600 overflow-hidden">
              <div className="whitespace-nowrap">
                ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutJourney;
