import React, { memo, useRef, useEffect } from "react";
import { gsap, Power2 } from "gsap";

const Collapse = ({ isOpen, children }) => {
  const collapseRef = useRef(null);

  useEffect(() => {
    const collapseElement = collapseRef.current;

    if (isOpen) {
      gsap.to(collapseElement, {
        height: "auto",
        duration: 0.4,
        ease: Power2.ease,
      });
    } else {
      gsap.to(collapseElement, {
        height: 0,
        duration: 0.4,
        ease: Power2.ease,
      });
    }
  }, [isOpen]);

  return (
    <div className="overflow-hidden h-0" ref={collapseRef}>
      {children}
    </div>
  );
};

export default memo(Collapse);
