import React, { useEffect } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const Styles = {
  container: (styles) => ({
    ...styles,
    background: "#280D02",
    "&.disabled": {
      background: "#17181A",
      pointerEvents: "none",
      "> div > div > div": {
        color: "#A2A5A8",
      },
    },
  }),
  control: (styles) => ({
    ...styles,
    background: "transparent",
    borderRadius: "0",
    borderColor: "#391A06",
    outline: "0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#79380e",
    },
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#fff",
    margin: "0",
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "16px 4px 16px 16px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#fff",
    cursor: "pointer",
    position: "relative",
    outline: "0",
    background: "#280D02",
    paddingLeft: "22px",
    borderBottom: "1px solid #FEF9F152",
    transition: "0.3s ease",
    padding: "16px",
    border: "0",
    ":active": {
      ...provided[":active"],
      backgroundColor: "transparent",
    },
    ":last-child": {
      borderBottom: "0px",
    },
    ".option-icon": {
      opacity: state.isSelected ? "1" : "0",
    },
    ":hover": {
      background: !state.isSelected ? "#391A06" : "inherit",
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: "#87562E",
  }),
  menu: (baseStyle) => ({
    ...baseStyle,
    minWidth: "130px",
    margin: 0,
    borderRadius: 0,
    backgroundColor: "#280D02",
  }),
  menuList: (baseStyle) => ({
    ...baseStyle,
    background: "#280D02",
    border: "0",
    borderRadius: "0",
    padding: "0",
  }),
  dropdownIndicator: (baseStyles, state) => {
    return {
      ...baseStyles,
      padding: "0",
      paddingRight: "12px",
      svg: {
        transform: state.isFocused ? "rotate(180deg)" : "rotate(0deg)",
      },
    };
  },
};

const SelectComponent = ({
  options,
  control,
  setValue,
  name,
  defaultValue,
  ...props
}) => {
  const onChangeHandler = (e) => {
    props.onChange && props.onChange(e);
  };

  useEffect(() => {
    if (setValue) {
      setTimeout(() => {
        setValue(name, defaultValue.value);
      }, 500);
    }
  }, [setValue]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ""}
        render={({ field: { onChange, ref } }) => (
          <Select
            {...props}
            menuPlacement="auto"
            options={options}
            onChange={(e) => {
              onChangeHandler(e);
              onChange(e.value);
            }}
            blurInputOnSelect={true}
            autoFocus={false}
            isSearchable={false}
            defaultMenuIsOpen={false}
            styles={Styles}
            components={{
              IndicatorSeparator: () => null,
            }}
            defaultValue={defaultValue}
            ref={ref}
          />
        )}
      />
    </>
  );
};

export default SelectComponent;
