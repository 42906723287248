import React, { memo } from "react";
import { Link } from "react-router-dom";

const FooterContent = ({ data }) => {
  return (
    <div className="footer w-full bg-neutral-700">
      <div className="xl:flex items-start md:gap-[15%]">
        <div className="mt-[10px] md:mt-0 block">
          <img
            src="/images/logo-footer.png"
            alt="Footer logo"
            className="w-[84px]"
            loading="lazy"
          />
          <div className="flex">
            <label className="text-neutral-350">Domicile</label>
            <div className="inline-block text-neutral-350 mx-[4px]">:</div>
            <span className="">{data.address.domicile}</span>
          </div>
          <div className="flex">
            <label className="text-neutral-350">Phone</label>
            <div className="inline-block text-neutral-350 mx-[4px]">:</div>
            <span className="">{data.address.phone}</span>
          </div>
          <div className="flex">
            <label className="text-neutral-350">Email</label>
            <div className="inline-block text-neutral-350 mx-[4px]">:</div>
            <span className="">
              <Link to={`mailto:${data.address.email}`} className="underline">
                {data.address.email}
              </Link>
            </span>
          </div>
        </div>
        <div className="md:flex md:w-full md:gap-[10%] xl:gap-[2.5rem]">
          <div className="mt-[32px] flex gap-[40px] md:gap-[10%] xl:gap-[2.5rem]">
            <div className="">
              <span className="text-neutral-400 block lg:whitespace-nowrap">
                Explore More
              </span>
              <div className="mt-8">
                <Link to="/portfolio" className="block mb-[3px]">
                  Portfolio
                </Link>
                <Link to="/talent" className="block">
                  Talent
                </Link>
              </div>
            </div>
            <div className="">
              <span className="text-neutral-400 block">Join Us</span>
              <div className="mt-8">
                <Link
                  to="/brand-form"
                  className="block mb-[3px] lg:whitespace-nowrap"
                >
                  Brand Form
                </Link>
                <Link to="/talent-form" className="block lg:whitespace-nowrap">
                  Talent Form
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-[32px] flex gap-[20px] md:gap-[10%] xl:gap-[2.5rem]">
            <div className="">
              <span className="text-neutral-400 block lg:whitespace-nowrap">
                Connect with Us
              </span>
              <div className="mt-8 gap-8 flex">
                <Link
                  to="https://wa.me/6281245952643"
                  target="_blank"
                  className="inline-block w-24 h-24 shrink-0"
                >
                  <img
                    src="/images/icon/ic-whatsapp-white.svg"
                    alt="Whatsapp logo"
                    className="w-24 h-24 block"
                    loading="lazy"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/eternal-petite/"
                  target="_blank"
                  className="inline-block w-24 h-24 shrink-0"
                >
                  <img
                    src="/images/icon/ic-linkedln-white.svg"
                    alt="Linkedln logo"
                    className="w-24 h-24 block"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
            <div className="">
              <span className="text-neutral-400 block lg:whitespace-nowrap">
                Follow Us On
              </span>
              <div className="mt-8 gap-8 flex">
                <Link
                  to="https://www.tiktok.com/@eternalpetite?is_from_webapp=1&sender_device=pc"
                  target="_blank"
                  className="inline-block w-24 h-24 shrink-0"
                >
                  <img
                    src="/images/icon/ic-tiktok-white.svg"
                    alt="Tiktok logo"
                    className="w-24 h-24 block"
                    loading="lazy"
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/petitemanagement/"
                  target="_blank"
                  className="inline-block w-24 h-24 shrink-0"
                >
                  <img
                    src="/images/icon/ic-ig-white.svg"
                    alt="Instagram logo"
                    className="w-24 h-24 block"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FooterContent);
