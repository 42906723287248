import React, { memo, useState, useEffect } from "react";
import InputController from "components/Form/InputController";

const TextInputComponent = (props) => {
  const [value, setValue] = useState(props.defaultValue || "");

  const onChangeHandler = (e) => {
    if (props.formatRegex) {
      const regex = props.formatRegex;

      if (e.target.value == "" || regex.test(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }

    props.onChange && props.onChange(e);
  };

  return (
    <input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      className={`outline-0 w-full text-[#fff] placeholder:text-primary-600 text-14 md:text-16 leading-[18px] md:leading-[20px] p-16 border-[1px] border-primary-500 bg-primary-300 ${props.className}`}
      onChange={onChangeHandler}
      value={value}
      disabled={props.disabled}
    />
  );
};

// to use defaultValue, need to add setValue from useFrom
const TextInput = ({
  control,
  register,
  name,
  setValue,
  rules,
  type,
  placeholder,
  className,
  defaultValue,
  formatRegex,
  disabled,
}) => {
  useEffect(() => {
    if (setValue) {
      setTimeout(() => {
        setValue(name, defaultValue);
      }, 500);
    }
  }, [setValue]);

  return (
    <InputController
      {...{
        control,
        register,
        name,
        rules: rules,
        render: (renderProps) => (
          <TextInputComponent
            {...renderProps}
            type={type}
            placeholder={placeholder}
            className={className}
            defaultValue={defaultValue}
            formatRegex={formatRegex}
            disabled={disabled}
          />
        ),
      }}
    />
  );
};

export default memo(TextInput);
