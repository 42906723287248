import React, { memo, useEffect, useRef } from "react";
import ReactPortal from "components/ReactPortal";
import { CSSTransition } from "react-transition-group";
import "./style.css";

const Modal = ({ children, isOpen, handleClose, className }) => {
  const nodeRef = useRef(null);

  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKey);
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    if (!isOpen) document.body.style.overflow = "unset";
  }, [isOpen]);

  return (
    <ReactPortal wrapperId="react-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div className={`modal ${className ?? ""}`} ref={nodeRef}>
          <div className="modal-overlay"></div>
          <div className="modal-container">
            <div className="modal-inner-container">
              <button className="modal-close" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path d="M1 1L25 25" stroke="#F5E5CB" />
                  <path d="M25 1L0.999999 25" stroke="#F5E5CB" />
                </svg>
              </button>
              <div className="modal-content">{children}</div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

export default memo(Modal);
