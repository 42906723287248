import React, { memo } from "react";
import "./style.css";

const CheckboxComponent = ({ register, name, className, label, ...props }) => {
  const onChangeHandler = (e) => {
    props.onChange && props.onChange(e);
  };

  return (
    <div className="flex items-center gap-[16px] p-16 relative">
      <input
        type="checkbox"
        name={name}
        id={`id-${name}`}
        onChange={(e) => onChangeHandler(e)}
        className="opacity-0 absolute invisible checkbox"
        {...register(name)}
        {...props}
      />
      {label && (
        <label
          htmlFor={`id-${name}`}
          className={`text-14 md:text-16 text-neutral leading-[18px] md:leading-[20px] pl-[29px] cursor-pointer select-none ${
            className ?? ""
          }`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default memo(CheckboxComponent);
