import React from "react";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";
import client from "data/Home/client.json";
import ClientDesktop from "./ClientDesktop";
import ClientMobile from "./ClientMobile";
import { ButtonTextUnderline } from "components/Button";
import { Link } from "react-router-dom";

const HomeClient = (props) => {
  return (
    <div className="relative py-[29px]">
      <div className="w-full relative md:max-w-[1009px] md:mx-auto md:px-16 md:pt-[100px]">
        <div className="py-[40px] px-16">
          <div className="block md:flex justify-between">
            <h1 className="text-[64px] md:text-[48px] leading-[51.2px] md:leading-[38.40px] text-neutral-100 font-taviraj">
              Our Client
            </h1>

            <div className="hidden md:block">
              <Link to="/about">
                <ButtonTextUnderline>Learn About Us</ButtonTextUnderline>
              </Link>
            </div>
          </div>
        </div>
        <div className="block min-h-[757px] md:min-h-[340px]">
          {props.windowDimension.width > MAX_RESPONSIVE_MOBILE ? (
            <ClientDesktop data={client.desktop} />
          ) : (
            <ClientMobile data={client.mobile} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withDimension(HomeClient);
