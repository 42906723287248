import React from "react";
import Layout from "components/Layout";
import TalentList from "components/Section/TalentList";
import FormChoice from "components/Section/FormChoice";
import { Link } from "react-router-dom";
import { ButtonOutline } from "components/Button";

const TalentPage = () => {
  return (
    <Layout selectedMenu="Talent">
      <div className="container max-w-screen-xl mx-auto md:px-20">
        <div className="w-full relative">
          <div className="flex justify-between md:mb-[124px] md:bg-primary-400">
            <div className="flex items-center justify-center relative">
              <div
                className="w-full h-full absolute top-0 left-0 z-0 hidden md:block"
                style={{
                  backgroundImage: 'url("/images/bg-about-pattern.png")',
                  background: 'url("/images/bg-about-pattern.png"), #280D02',
                  backgroundBlendMode: "soft-light, normal",
                }}
              ></div>
              <div className="py-[40px] px-16 md:p-0 md:max-w-[70%] relative z-[1]">
                <h1 className="text-[64px] md:text-[48px] leading-[51.2px] md:leading-10 text-neutral-100 font-taviraj">
                  Our Talent
                </h1>
                <p className="font-light text-[20px] text-primary-800 block mt-[20px] md:mt-[32px]">
                  We have over 1000 Talents for your brand, here are some of the
                  astounding talents that we have.
                </p>
              </div>
            </div>
            <img
              src="/images/banner/banner-talent-1.webp"
              alt=""
              className="max-w-[546px] hidden md:block w-3/5 md:h-[360px]"
              loading="lazy"
            />
          </div>
          <TalentList />
          <div className="block md:flex md:justify-center pt-[40px] md:pt-[72px] pb-[20px] md:pb-[72px] flex items-center gap-[24px] md:gap-[40px] px-[16px]">
            <Link to="/portfolio" className="block">
              <ButtonOutline>Portfolio</ButtonOutline>
            </Link>
            <Link to="/about" className="block">
              <ButtonOutline>About Us</ButtonOutline>
            </Link>
          </div>
        </div>
        <FormChoice />
      </div>
    </Layout>
  );
};

export default TalentPage;
