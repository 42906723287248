import React from "react";
import partnership from "data/About/partnership.json";
import Marquee from "react-fast-marquee";

const AboutPartnership = () => {
  return (
    <div className="w-full block pt-[30px] py-[100px]">
      <div className="max-w-[1320px] mx-auto">
        <div className="px-16">
          <h3 className="font-taviraj text-[63px] md:text-[48px] leading-[50.4px] md:leading-[38.4px] text-[#FFF5F0] md:text-center pb-[40px] md:pb-[80px]">
            Special Partnership
          </h3>
        </div>
        <div className="md:px-16">
          <div className="w-full overflow-hidden bg-neutral-50">
            <Marquee>
              <div className="flex bg-neutral-50">
                {partnership?.map((item, index) => (
                  <div className="shrink-0 relative bg-neutral-50" key={index}>
                    <img
                      src={`/images/thumb/${item.thumbnail}`}
                      alt={item.thumbnail}
                      className="w-full max-w-[168px] md:max-w-[184px] object-center object-cover relative z-0 aspect-square"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </Marquee>
          </div>
        </div>
        <div className="px-16 pt-[40px] md:mt-[80px]">
          <p className="text-taviraj italic text-16 md:text-20 leading-[27px] md:leading-[34px] tracking-[0.03em] text-primary-800 md:text-center w-4/5 md:w-full">
            We are grateful for the trust placed in us by these brands, ranging
            from MSMEs to large corporations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPartnership;
