import React from "react";
import portfolio from "data/Portfolio/list.json";
import withDimension from "utils/withDimension";
import PortfolioListMobile from "./PortfolioListMobile";
import PortfolioListDesktop from "./PortfolioListDesktop";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";
import "./style.css";

const PortfolioList = (props) => {
  return (
    <>
      <div className="w-full portfolio-slider">
        {props.windowDimension.width > MAX_RESPONSIVE_MOBILE ? (
          <PortfolioListDesktop data={portfolio} />
        ) : (
          <PortfolioListMobile data={portfolio} />
        )}
      </div>
    </>
  );
};

export default withDimension(PortfolioList);
