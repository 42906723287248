import React, { useState } from "react";
import Modal from "components/Modal";

const PortfolioListDesktop = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState();

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleClickModal = (item) => {
    setDataModal(item);
    setOpenModal(true);
  };

  return (
    <>
      <div className="w-full max-w-[1056px] mx-auto px-16">
        <div className="flex flex-wrap m-[-8px]">
          {data?.map((item, index) => (
            <div className="relative w-1/3 p-8" key={index}>
              <div
                className="block relative pb-[100%] cursor-pointer"
                onClick={() => handleClickModal(item)}
              >
                <img
                  src={`/images/thumb/${item.list[0].thumbnail}`}
                  alt={item.list[0].thumbnail}
                  className="w-full h-full block absolute z-0 object-cover object-center"
                />
              </div>
              <div className="w-full h-[113px] bg-main-slider-overlay absolute bottom-0 left-0 z-[1]"></div>
              <div className="absolute w-[72px] h-[72px] bottom-[19px] left-[16px] z-[1]">
                <img
                  src={`/images/logo/${item.logo}`}
                  alt={item.logo}
                  className="w-full h-full relative object-cover object-center"
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        handleClose={handleOpenModal}
        isOpen={openModal}
        className="!items-start"
      >
        <>
          <div className="flex">
            <div className="w-1/2 relative">
              {dataModal?.review_type == "video" ? (
                <>
                  <iframe
                    src={dataModal?.review ?? ""}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    title="Embedded youtube"
                    className="w-full object-cover object-center block aspect-square"
                    allowFullScreen
                    style={{ border: "0" }}
                  ></iframe>
                </>
              ) : (
                <img
                  src={`/images/thumb/${dataModal?.review ?? ""}`}
                  alt=""
                  className="w-full object-cover object-center block aspect-square"
                  loading="lazy"
                />
              )}
            </div>
            <div className="w-1/2">
              <div className="block pl-16">
                <div className="text-18 text-neutral">
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-tight text-primary-800">
                      Brand :
                    </span>
                    <span className="block font-normal">
                      {dataModal?.brand ?? ""}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-tight text-primary-800">
                      Service Type :
                    </span>
                    <span className="block font-normal">
                      {dataModal?.service_type ?? ""}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-tight text-primary-800">
                      Talent :
                    </span>
                    <span className="block font-normal">
                      {dataModal?.talent ?? ""}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-tight text-primary-800">
                      Platform :
                    </span>
                    <span className="block font-normal">
                      {dataModal?.platform ?? ""}
                    </span>
                  </div>
                  <div className="mb-8 last:mb-0">
                    <span className="block font-taviraj leading-tight text-primary-800">
                      Date :
                    </span>
                    <span className="block font-normal">
                      {dataModal?.date ?? ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[48px] pt-[24px] border-t-[1px] border-primary-800">
            <div className="flex flex-wrap m-[-8px]">
              {dataModal &&
                dataModal?.list.map((item, index) => (
                  <div className="w-1/2" key={index}>
                    <div className="p-8">
                      <img
                        src={`/images/thumb/${item.thumbnail ?? ""}`}
                        alt=""
                        className="w-full object-cover object-center block aspect-square"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default PortfolioListDesktop;
