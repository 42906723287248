import React, { useState, useEffect } from "react";
import withDimension from "utils/withDimension";
import { MAX_RESPONSIVE_MOBILE } from "store/constants";

const AboutIntro = (props) => {
  const [calcContainerOuter, setCalcContainerOuter] = useState(0);

  useEffect(() => {
    let marginContainer = (props.windowDimension.width - 1196) / 2;
    let exactContainer = marginContainer + 1196 + "px";

    setCalcContainerOuter(exactContainer);

    // This effect will run when the component mounts and whenever props.windowDimension.width changes.
  }, [props, props.windowDimension.width]);

  return (
    <div className="block mb-[20px]">
      <div className="w-full max-w-[1200px] relative mx-auto">
        <div
          className="w-full h-full absolute top-0 left-0 z-0 hidden md:block"
          style={{
            backgroundImage: 'url("/images/bg-about-pattern.png")',
            background: 'url("/images/bg-about-pattern.png"), #280D02',
            backgroundBlendMode: "soft-light, normal",
          }}
        ></div>
        <div className="w-full flex pt-32 pb-[28px] px-16 md:pl-0 md:p-0 relative">
          <div className="flex flex-col justify-between md:p-[35px] lg:p-[70px]">
            <h1 className="text-[72px] md:text-[48px] leading-[57.6px] md:leading-[38.40px] text-neutral-100 font-taviraj mb-32">
              The <br />
              Eternal Petite
            </h1>
            <p className="text-20 leading-[25px] text-primary-900">
              Over time, we also continue to innovate and upgrade a lot so that
              we can continue to grow, so in 2022 we decided to make Eternal
              Petite a legal company engaged in the talent ansocial media
              agency.
            </p>
          </div>
          {props.windowDimension.width > MAX_RESPONSIVE_MOBILE && (
            <div className="hidden md:block w-[532px] aspect-square shrink-0">
              <img
                src={`/images/thumb/thumb-about-desktop.webp`}
                alt={`thumb about desktop`}
                className="max-w-[532px] w-full h-[532px] object-center object-cover relative z-0 aspect-square"
                loading="lazy"
              />
            </div>
          )}
        </div>
        {props.windowDimension.width === MAX_RESPONSIVE_MOBILE && (
          <div className="hidden md:block">
            <img
              src={`/images/thumb/thumb-about-desktop.webp`}
              alt={`thumb about desktop`}
              className="w-full object-center object-cover relative z-0 aspect-square"
              loading="lazy"
            />
          </div>
        )}
        {props.windowDimension.width < MAX_RESPONSIVE_MOBILE && (
          <div className="block md:block">
            <img
              src={`/images/thumb/thumb-about-mobile.webp`}
              alt={`thumb about mobile`}
              className="w-full object-center object-cover relative z-0"
              loading="lazy"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withDimension(AboutIntro);
