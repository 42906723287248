import React, { memo } from "react";
import { CardSliderTalentMobile } from "components/Cards/CardSliderTalentMobile";
import { MainSliderMobile } from "components/Slider";
import { Link } from "react-router-dom";

const HomeTalent = ({ talents }) => {
  return (
    <>
      <div className="w-full relative">
        <div className="py-[40px] px-16">
          <h1 className="text-[64px] leading-[51.2px] text-neutral-100 font-taviraj">
            Featured Talent
          </h1>
        </div>
        <div className="w-full">
          <MainSliderMobile autoPlay={true}>
            {talents?.map((item, index) => (
              <div className="!block" key={index}>
                <CardSliderTalentMobile {...item} />
              </div>
            ))}
          </MainSliderMobile>
          <div className="w-full bg-tertiary-600 p-[11px] text-right">
            <Link to="/talent" className="inline-block">
              <span className="text-neutral-100 text-16 leading-[20px]">{`More Talent >>`}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HomeTalent);
