import React from "react";
import "./button.css";

const ButtonPrimary = ({ children, className }) => {
  return (
    <>
      <button
        className={`text-16 leading-[20px] text-neutral bg-primary-700 p-8 ${
          className ?? ""
        }`}
      >
        {children}
      </button>
    </>
  );
};

const ButtonOutline = ({ children, className }) => {
  return (
    <button
      className={`text-14 md:text-20 font-taviraj font-light text-primary-900 py-8 px-16 border-[1px] border-primary-600 ${
        className ?? ""
      }`}
    >
      {children}
    </button>
  );
};

const ButtonTextUnderline = ({ children, className }) => {
  return (
    <button className={`button-underline ${className ?? ""}`}>
      {children}
    </button>
  );
};

const ButtonPink = ({ children, className, ...props }) => {
  return (
    <button
      className={`button-pink bg-tertiary-600 border-[1px] border-tertiary-400 text-primary-900 font-taviraj italic text-20 leading-[34px] p-[19px] w-full md:w-auto md:min-w-[312px] transition-all duration-300 hover:bg-[#af6579] hover:border-tertiary-600 ${
        className ?? ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export { ButtonPrimary, ButtonOutline, ButtonTextUnderline, ButtonPink };
